import { graphql, Link, StaticQuery } from 'gatsby';
import React from 'react';
import Author from './Author';
import Contacts from './Contacts';
import Copyright from './Copyright';
import Menu from './Menu';
import styles from './Sidebar.module.scss';

export const PureSidebar = ({ data, isIndex }) => {
  const { author, title, subtitle, copyright, menu } = data.site.siteMetadata;

  return (
    <div className={styles['sidebar']}>
      <div className={styles['sidebar__inner']}>
        <Link to="/">
          <h1 className={styles['sidebar__inner-title']}>{title}</h1>
        </Link>
        <h2 className={styles['sidebar__inner-subtitle']}>{subtitle}</h2>
        <Author author={author} isIndex={isIndex} />
        <Contacts contacts={author.contacts} />
        <Menu menu={menu} />
        <Copyright copyright={copyright} />
      </div>
    </div>
  );
};

export const Sidebar = props => (
  <StaticQuery
    query={graphql`
      query SidebarQuery {
        site {
          siteMetadata {
            title
            subtitle
            copyright
            menu {
              label
              path
            }
            author {
              name
              photo
              bio
              contacts {
                twitter
                github
                stackOverflow
              }
            }
          }
        }
      }
    `}
    render={data => <PureSidebar {...props} data={data} />}
  />
);

export default Sidebar;
